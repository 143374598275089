@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; */

@layer components{
  .pink-btn{
    @apply bg-[#FD3798] text-white font-bold text-lg py-3 px-10 rounded-full
  }
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #bfbebe;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:root {
  --linear: linear-gradient(180deg, #ffffff 55.88%, #fef9ed 100%);
  --linear2: linear-gradient(
      0deg,
      rgba(242, 195, 71, 0.1),
      rgba(242, 195, 71, 0.1)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  --dark: #2e3147;
  --primary: #4784ca;
  --secondary: #00844f;
}

body {
  font-family: "Poppins", sans-serif;
}

.ant-radio-button-checked {
  background-color: var(--primary);
}

.ant-radio-button-checked:hover {
  color: var(--primary);
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

.ant-table-cell {
  color: #2e3147;
  font-weight: 400;
}

.btn-pressed {
  background: #eef0f4;
  border-radius: 15%;
  box-shadow: inset 9.91px 9.91px 20px #d9dade,
    inset -9.91px -9.91px 20px #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-select-selector {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 0px !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 160px !important;
  height: 160px !important;
}

.w-\[fit-content\] {
  width: fit-content !important ;
}

.ant-upload-list-item-container {
  width: 160px !important;
  height: 160px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  background-color: transparent !important;
  border-inline-end: 0px;
}

.ant-switch {
  background-color: #e5e5e5 !important;
}

.ant-switch-checked {
  background-color: #4784ca !important;
}

/* Calendat scheldule */

.rbc-header {
  /* background-color: var(--primary); */
  /* color: white; */
  font-weight: 600 !important;
  font-size: 1em !important;
  padding: 10px 0px !important;
}

.rbc-time-view .rbc-row {
  min-height: 60px;
}

.rbc-timeslot-group {
  min-height: 60px !important;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  max-height: 60vh;
  overflow-y: scroll;
  scroll-snap-align: center;
}

.rbc-event {
  background-color: transparent !important;
  border: none !important;
  height: auto !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  margin-right: 0px !important;
}

.rbc-toolbar {
  gap: 10px;
  justify-content: "flex-start" !important;
}

.rbc-time-content > .rbc-day-slot {
  min-width: 80px;
}

.rbc-time-header {
  width: 100%;
  overflow-x: auto;
}

.rbc-header {
  min-width: 80px;
  background-color: #4784ca1a;
  color: var(--primary);
  font-weight: 600 !important;
}

.rbc-btn-group .rbc-active {
  background-color: var(--primary) !important;
  color: white !important;
  border-color: transparent;
  font-weight: 600;
}

.rbc-toolbar button:hover {
  color: white !important;
  background-color: var(--primary) !important;
}

.rbc-time-slot,
.rbc-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-toolbar-label {
  font-weight: 600;
}

.rbc-month-row {
  min-height: 120px;
}

@media only screen and (max-width: 1024px) {
  .rbc-toolbar {
    flex-direction: column !important;

    /* align-items: start !important; */
  }
}

/* Calendar end */

.ant-btn-primary {
  background-color: #4784ca !important;
}
